<template>
  <div>
    <DoodleGeneralForm title="Reset Password"
                       in-progress-message="JDoodle is trying to change your password, Please wait..."
                       success-message="Password Successfully Changed."
                       action-text="Change Password"
                       :form="doodleForm"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       @action="formAction"
                       @close="goBack">
      <DoodleInput field-name="newPassword" label-text="New Password" type="password"
                   left-icon="lock"
                   rule="required|min:5"
                   v-model="doodleForm.newPassword" place-holder="" reff="newPassword"/>
      <DoodleInput field-name="retypeNewPassword" label-text="Retype New Password" type="password"
                   left-icon="lock"
                   :rule="{required:true, confirmed:doodleForm.newPassword}"
                   v-model="doodleForm.retypeNewPassword" place-holder=""/>
    </DoodleGeneralForm>
  </div>
</template>

<script>
import formMixin from '../assets/javascript/form-mixin'
import DoodleGeneralForm from '../components/Utils/DoodleGeneralForm'
import DoodleInput from '../components/Utils/DoodleInput'
import backButtonMixin from '@/assets/javascript/back-button-mixin'

export default {
  name: 'resetPassword',
  components: { DoodleInput, DoodleGeneralForm },
  mixins: [formMixin, backButtonMixin],
  data: function () {
    return {
      doodleForm: {
        registrationCode: null,
        newPassword: null,
        retypeNewPassword: null
      }
    }
  },
  mounted () {
    if (this.$store.state.isUserLoggedIn === true) {
      this.$router.push('/')
    }

    let failureAction = () => {
      this.doodleForm.errorMessage = 'Sorry, we have no record of reset password with this code, or it has expired.'
      this.doodleForm.completed = true
    }

    this.doodleForm.registrationCode = this.$route.query.t

    if (!this.doodleForm.registrationCode) {
      failureAction()
      return
    }

    this.executeAPIWitoutValiation({
      url: '/api/register/resetPassword',
      data: {
        't': this.doodleForm.registrationCode
      },
      method: 'get',
      successAction: null,
      failureAction: failureAction,
      markCompleted: false,
      form: this.doodleForm,
      jdaCategory: window.jda.CATEGORY.GLOBAL,
      jdaEvent: 'reset-password',
      jdaLabel: 'init'
    })
  },
  methods: {
    formAction () {
      this.executeAPI({
        url: '/api/register/resetPassword',
        data: {
          't': this.doodleForm.registrationCode,
          'newPassword': this.doodleForm.newPassword,
          'retypeNewPassword': this.doodleForm.retypeNewPassword },
        method: 'post',
        successAction: (data) => {
          this.$store.commit('login', data.username)
        },
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GLOBAL,
        jdaEvent: 'reset-password',
        jdaLabel: 'reset'
      })
    }
  }
}
</script>
